import React from "react";
import { Link } from "react-router-dom";

export default function GarzaFooter() {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="fixed bottom-0 right-0 flex p-6 text-xs">
       <Link className="block mr-2 hover:underline" to="/">
        Home
      </Link>
      <Link className="block mr-2 hover:underline" to="/contact">
        Contact
      </Link>
      <Link className="block mr-2 hover:underline" to="/terms">
        Terms
      </Link>
      <Link className="block mr-2 hover:underline" to="/privacy">
        Privacy
      </Link>
      <p className="block mr-2">
        &copy; {currentYear} The Garza Organization
      </p>
    </footer>
  );
}
