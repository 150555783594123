import React from "react";

export default function PrivacyPage() {
  return (
    <section className="w-1/2 container mx-auto">
      <header className="mb-10">
        <h1 className="text-center text-5xl font-medium">Privacy</h1>
      </header>
      <section className="text-white text-sm">
        <p>
          Thank you for visiting the Amelie Dior Marketing Agency website
          ("Website"). At Amelie Dior, we are committed to protecting your
          privacy and ensuring the security of your personal information. This
          Privacy Policy outlines how we collect, use, and safeguard the
          information you provide to us through the Website. By using our
          Website, you agree to the terms of this Privacy Policy. 1. Information
          We Collect: 1.1 Personal Information: We may collect personal
          information such as your name, email address, phone number, and other
          contact details when you voluntarily provide them to us through forms
          on our Website, such as contact forms or newsletter subscriptions. ‍
          1.2 Non-Personal Information: We may automatically collect certain
          non-personal information, including but not limited to your IP
          address, browser type, operating system, and browsing patterns,
          through the use of cookies or similar technologies. This information
          is used to analyze trends, administer the Website, track user
          movements, and gather demographic information for internal purposes.
          You can disable cookies through your browser settings, although this
          may affect your experience on our Website.Use of Information: 2.1
          Personal Information: We may use the personal information you provide
          to us to respond to your inquiries, fulfill your requests, provide you
          with information about our services, send you marketing communications
          with your consent, and improve our Website and services. 2.2
          Non-Personal Information: Non-personal information is used for
          statistical purposes, to improve our Website, and enhance user
          experience.Disclosure of Information: 3.1 Service Providers: We may
          disclose your personal information to trusted third-party service
          providers who assist us in operating our Website and delivering our
          services. These service providers have access to personal information
          solely for the purpose of performing their duties and are
          contractually obligated to keep it confidential. 3.2 Legal Compliance:
          We may disclose your personal information if required by law or in
          response to a valid legal request, such as a court order or government
          inquiry. 3.3 Business Transfers: In the event of a merger,
          acquisition, or sale of all or a portion of our assets, your personal
          information may be transferred to the acquiring entity as part of the
          transaction, subject to appropriate confidentiality agreements. 4.
          Data Security: ‍We implement industry-standard security measures to
          protect your personal information from unauthorized access,
          disclosure, alteration, and destruction. However, please note that no
          method of transmission or storage over the internet is 100% secure,
          and we cannot guarantee the absolute security of your information. 5.
          Third-Party Websites: Our Website may contain links to third-party
          websites or services that are not owned or controlled by Amelie Dior.
          This Privacy Policy applies only to our Website. We encourage you to
          review the privacy policies of any third-party websites you visit. 6.
          Children's Privacy: ‍Our Website is not directed towards individuals
          under the age of 13. We do not knowingly collect personal information
          from children under the age of 13. If we become aware that we have
          collected personal information from a child under the age of 13
          without parental consent, we will take steps to remove that
          information from our servers. 7. Your Rights: You have the right to
          access, correct, update, or delete your personal information that we
          hold. If you would like to exercise any of these rights or have any
          questions regarding our Privacy Policy, please contact us using the
          information provided below. 8. Changes to this Privacy Policy: We may
          update this Privacy Policy from time to time. Any changes will be
          effective upon posting on this page. We encourage you to review this
          Privacy Policy periodically to stay informed about how we collect,
          use, and protect your information.
        </p>
      </section>
    </section>
  );
}
