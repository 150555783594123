
export const GARZA_COMPANIES = [
  {
    name: "Emerson",
    url: "http://emersonacademy.org",
  },
  // {
  //   name: "Dreamers",
  //   url: "https://www.youtube.com/@thedreamers.",
  // },
  {
    name: "VisionLand",
    url: "http://visionland.org",
  },
];

export const GARZA_ACCOUNTS = [
];