import React from "react";
import { Outlet } from "react-router-dom";
import GarzaFooter from "./GarzaFooter";

export default function PageWrapper() {
  return (
    <section className="relative min-h-screen max-h-screen min-w-screen max-w-screen bg-black text-white flex items-center justify-center flex-col">
      <Outlet />
      <GarzaFooter />
    </section>
  )
}
