import React from "react";
import { SocialIcon } from "react-social-icons";
import { GARZA_ACCOUNTS, GARZA_COMPANIES } from "../constants/garzaConstants";

export default function HomePage() {
  return (
    <section>
      <header className="mb-2">
        <h1 className="text-center font-garzaFont text-[100px] sm:text-[120px]">
          GARZA
        </h1>
      </header>
      <nav className="flex items-center justify-center flex-col sm:flex-row mb-4">
        {GARZA_COMPANIES.map((companyItem) => (
          <a
            className="font-garzaCompany block my-2 sm:mx-2 sm:my-0 text-md transition duration-300 ease-in-out hover:underline"
            href={companyItem.url}
            rel="noreferrer"
            target="_blank"
          >
            {companyItem.name}
          </a>
        ))}
      </nav>
      <nav className="flex items-center justify-center">
        {GARZA_ACCOUNTS.map((accountItem) => (
          <a
            className="block mx-2 rounded-full overflow-hidden transition-all duration-300 ease-in-out hover:bg-garzaDarkGray"
            key={accountItem}
            href={accountItem}
            target="_blank"
            rel="noreferrer"
          >
            <SocialIcon
              url={accountItem}
              bgColor="transparent"
              fgColor="#FFF"
              target="_blank"
              rel="noreferrer"
            />
          </a>
        ))}
      </nav>
    </section>
  );
}
