import React from "react";

export default function ContactPage() {
  return (
    <section className="w-1/2 container mx-auto">
      <header className="mb-10">
        <h1 className="text-center text-5xl font-medium">Contact</h1>
      </header>
      <section className="text-center text-white text-sm">
        <header className="mb-2">
          <p className="font-bold mb-1 font-garzaCompany text-sm">
            The Garza Organization
          </p>
          <p className="mb-1 font-garzaCompany text-sm">555 Burrard Street</p>
          <p className="mb-1 font-garzaCompany text-sm">
            Vancouver, BC V7X 1M8
          </p>
        </header>
        <a
          className="block font-garzaCompany text-sm hover:underline"
          href="mailto:organization@garza.ca"
        >
          organization@garza.ca
        </a>
      </section>
    </section>
  );
}
