import React from "react";

export default function TermsPage() {
  return (
    <section className="w-1/2 container mx-auto">
      <header className="mb-10">
        <h1 className="text-center text-5xl font-medium">Terms of Service</h1>
      </header>
      <section className="text-white text-sm">
        <p>
          1. Acceptance of Terms: By accessing or using the Garza
          Marketing Agency website ("Website") and services, you agree to comply
          with these Terms of Service. If you do not agree with these terms,
          please do not use our Website or services. 2. Services: Garza
          Marketing Agency provides marketing services. The specific details and
          scope of the services will be outlined in a separate agreement or
          proposal. 3. User Obligations: You agree to use our Website and
          services in compliance with applicable laws and regulations. You are
          responsible for maintaining the confidentiality of your account
          credentials and for all activities that occur under your account. 4.
          Intellectual Property: ‍Garza retains ownership of its Website,
          services, and content. You may not reproduce, modify, or distribute
          our intellectual property without our written consent. 5. Limitation
          of Liability: ‍Garza and its officers, directors, employees, or
          agents shall not be liable for any damages arising from your use of
          our Website or services. 6. Termination: ‍Garza reserves the
          right to suspend or terminate your access to our Website or services
          at any time, without prior notice or liability. 7. Governing Law:
          ‍These Terms of Service shall be governed by and construed in
          accordance with the laws of British Columbia and Canada. 8. Contact
          Us: If you have any questions or concerns about these Terms of
          Service, please contact us using the information provided below.
        </p>
      </section>
    </section>
  );
}
